import {
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import axios from "axios";

import { Tag, TagGroup } from "@fluentui/react-tags";
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import './ProductOrderHistory.css';
import Layout from "./components/Layout";
import { Base_Url, auth } from "./services/firebase";
import { CalanderSVG, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import { generateExelData } from "./components/ReportUtil";
const { startOfMonth, endOfMonth, subMonths } = require('date-fns');

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    tableLayout: {
        overflowX: 'auto',    // Enable horizontal scroll
        display: 'block',
    },
});

const columns = [
    { columnKey: "customerName", label: "Customer Name", width: '200px' },
    { columnKey: "customer_email", label: "Customer Email", width: '200px' },
    { columnKey: "invoiceStatus", label: "Invoice Status", width: '150px' },
    { columnKey: "invoiceNumber", label: "Invoice Number", width: '200px' },
    { columnKey: "invoiceDate", label: "Invoice Date", width: '200px' },
    { columnKey: "subtotal", label: "Subtotal", width: '200px' },
    { columnKey: "invoiceTax", label: "Invoice Tax", width: '200px' },
    { columnKey: "TotalInvoiced", label: "Total Invoiced", width: '200px' },
    { columnKey: "InvoiceLineItemDescription", label: "Invoice Line Item Description", width: '250px' },
    { columnKey: "ProductName", label: "Product Name", width: '200px' },
    { columnKey: "InvoiceLineItemQuantity", label: "Invoice Line Item Quantity", width: '100px' },
    { columnKey: "AmountDue", label: "Amount Due", width: '200px' },
    { columnKey: "PaidStatus", label: "Paid Status", width: '200px' },
    { columnKey: "AmountPaid", label: "Amount Paid", width: '200px' },
    { columnKey: "PaymentStatus", label: "Payment Status", width: '200px' },
    { columnKey: "PaymentDate", label: "Payment Date", width: '200px' },
    { columnKey: "SellerMessage", label: "Seller Message", width: '200px' },
    { columnKey: "PaymentFee", label: "Payment Fee", width: '200px' },
    { columnKey: "PaymentAmount", label: "Payment Amount", width: '200px' },
    { columnKey: "AmountRefunded", label: "Amount Refunded", width: '200px' },
    { columnKey: "PayoutDate", label: "Payout Date", width: '200px' },
    { columnKey: "PaymentMetadataOtherInfo", label: "PaymentMetadata (other info)", width: '250px' },
    { columnKey: "PaymentMetadataProductInfo", label: "Payment Metadata (product info)", width: '250px' },
    { columnKey: "CreditMemos", label: "Credit Memos", width: '200px' },
];


function ReportHistory() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [invoiceHistory, setInvoiceHistory] = useState([]);
    const [filterInvoiceHistory, setFilterInvoiceHistory] = useState([]);

    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [visibleTags, setVisibleTags] = useState([]);
    const [dateTags, setDateTags] = useState([]);
    const firstTagRef = useRef(null);
    const dateTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [filterOptions, setFilterOptions] = useState([
        { id: 1, name: 'Paid', selected: false },
        { id: 2, name: 'Open', selected: false },
        { id: 3, name: 'Past Due', selected: false },
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        //setIsLoading(true)
        // Set the start date to one month ago
        const startDate = moment().subtract(1, 'months'); // One month ago
        const endDate = moment(); // Today

        // Set the selected dates to state
        //setSelectedStartDate(startDate);
        //setSelectedEndDate(endDate);
        //fetchDataFromAPI(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    }, [user, loading]);

    useEffect(() => {
        if (invoiceHistory && invoiceHistory.length > 0) {
            if (visibleTags && visibleTags.length > 0) {
                // Extract the values from visibleTags for easy comparison
                const tagValues = visibleTags.map(tag => tag.value.toLowerCase());
                // Filter the invoiceHistory based on tagValues matching the invoice status
                const filtered = invoiceHistory.filter(invoice =>
                    tagValues.includes(invoice.status.toLowerCase())
                );
                setFilterInvoiceHistory(filtered); // Store the filtered list in state
            } else {
                setFilterInvoiceHistory(invoiceHistory); // No filtering if no visible tags
            }
        }
    }, [visibleTags]);

    useEffect(() => {
        // Fetch events when the page number changes
        if (page > 1) {
        }
    }, [page]);

    const fetchDataFromAPI = (startDate, endDate) => {
        const config = {
            method: 'get',
            url: `${Base_Url}/getInvoicesAndPaymentsAndCreditNoteByDateRange`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: {
                startDate: startDate,
                endDate: endDate
            }
        };
        setIsLoading(true)

        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data) {
                        setInvoiceHistory(response.data.invoices)
                        if (visibleTags && visibleTags.length > 0) {
                            const tagValues = visibleTags.map(tag => tag.value.toLowerCase());
                            const filtered = response.data.invoices.filter(invoice =>
                                tagValues.includes(invoice.status.toLowerCase())
                            );
                            setFilterInvoiceHistory(filtered); // Store the filtered list in state
                        } else {
                            setFilterInvoiceHistory(response.data.invoices)
                        }
                    }
                    setIsLoading(false)
                } else {
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.error(err);
                alert("An error occurred while fetching charge data.");
            });
    }

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        // Update filter options and set selected state to false
        const updatedFilterOptions = filterOptions.map(option =>
            option.name == value ? { ...option, selected: false } : option
        );
        setFilterOptions([...updatedFilterOptions]);
    };

    const removeDateTagItem = (e, { value }) => {
        //setDateTags([...dateTags].filter((tag) => tag.value !== value));
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    const toggleSelect = (id) => {
        setFilterOptions((prevOptions) =>
            prevOptions.map((option) =>
                option.id === id ? { ...option, selected: !option.selected } : option
            )
        );
    };

    // Function to handle checkbox toggle
    const handleApplyFilter = (updatedFilterOptions = filterOptions) => {
        // Ensure that updatedFilterOptions is an array
        if (!Array.isArray(updatedFilterOptions)) {
            console.error('Expected updatedFilterOptions to be an array', updatedFilterOptions);
            return; // Exit the function if not an array
        }

        const selectedOptions = updatedFilterOptions.filter(option => option.selected);
        const selectedValues = selectedOptions.map(option => option.name);

        //TODO: Filter data
        const updatedTags = selectedOptions.map(option => ({
            value: option.name,
            children: option.name
        }));
        setVisibleTags(updatedTags);
        setPopupVisible(false);
    };
    const handleCancelFilter = () => {
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelDateFilter = () => {
        // Close the popup
        setSelectedOption(null)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        let startDate = null;
        let endDate = null;
        if (selectedOption == "CustomDate") {
            setInvoiceHistory([])
            setFilterInvoiceHistory([])
            if (selectedStartDate && selectedEndDate) {
                setSelectedOption("CustomDate");
                const formattedStartDate = selectedStartDate.format('YYYY-MM-DD'); // Use Moment's format
                const formattedEndDate = selectedEndDate.format('YYYY-MM-DD'); // Use Moment's format
                fetchDataFromAPI(formattedStartDate, formattedEndDate);
                let tagStr = `${formattedStartDate} - ${formattedEndDate}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        } else {
            setInvoiceHistory([])
            setFilterInvoiceHistory([])
            const today = new Date();

            switch (selectedOption) {
                case "Today":
                    startDate = moment(today).startOf('day').format('YYYY-MM-DD HH:mm'); // Start of today at 12:00 AM
                    endDate = moment(today).endOf('day').format('YYYY-MM-DD HH:mm'); // End of today at 11:59 PM
                    break;

                case "Yesterday":
                    const yesterday = moment(today).subtract(1, 'day');
                    startDate = yesterday.startOf('day').format('YYYY-MM-DD HH:mm'); // Start of yesterday at 12:00 AM
                    endDate = yesterday.endOf('day').format('YYYY-MM-DD HH:mm'); // End of yesterday at 11:59 PM
                    break;

                case "This month":
                    startDate = moment(today).startOf('month').format('YYYY-MM-DD'); // Start of current month
                    endDate = moment(today).endOf('month').format('YYYY-MM-DD'); // End of current month
                    break;

                case "Past month":
                    const pastMonth = moment(today).subtract(1, 'month');
                    startDate = pastMonth.startOf('month').format('YYYY-MM-DD'); // Start of past month
                    endDate = pastMonth.endOf('month').format('YYYY-MM-DD'); // End of past month
                    break;

                default:
                    startDate = null;
                    endDate = null;
                    break;
            }
            if (startDate && endDate) {
                fetchDataFromAPI(startDate, endDate);
                let tagStr = `${startDate} - ${endDate}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        }
        setPopupVisible(false);
        setPopupVisibleDate(false);
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        //        setSelectedStartDate(date);
        setSelectedStartDate(moment(date)); // Ensure it's a Moment object
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        //setSelectedEndDate(date);
        setSelectedEndDate(moment(date)); // Ensure it's a Moment object
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick(from) {
        const dataArr = filterInvoiceHistory;
        if (dataArr.length > 0) {
            if (from == 2) {
            } else {
                generateExelData(dataArr, exportSelectedOption, allUsers)
            }
        }
    }

    const renderCreditNote = (item) => {
        if (item && item.credit_adjustments && item.credit_adjustments.length > 0 && item.credit_adjustments[0]) {
            return (
                <Label>{item.credit_adjustments[0]?.memo}</Label>
            )
        } else {
            return (<Label></Label>)
        }
    }
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>Invoice status</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Invoice Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    appearance="outline"
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                    <div style={{ width: '10px' }} />
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className="table-layout2">
                    <Table aria-label="Table with cell actions" style={{ minWidth: '4600px' }}> {/* Set min-width here */}
                        <TableHeader className="list-row2">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ width: column.width }}> {/* Set width here */}
                                        <span className="listHeaderText">{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {filterInvoiceHistory.length > 0 && (
                            <TableBody>
                                {filterInvoiceHistory.map((invoice) => (
                                    <TableRow key={invoice.number}>
                                        <TableCell >
                                            <TableCellLayout>{invoice.customer_name}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.customer_email}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.status.toUpperCase()}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.number}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{formatDate(invoice.datetimestamp)}</TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>${convertStripeAmount(invoice.subtotal).toFixed(2)}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice.tax) || '0'}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice.total_invoiced).toFixed(2) || '0'}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {invoice.line_items && invoice.line_items.length > 0 ? (
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                        {invoice.line_items.map((item, index) => (
                                                            <div key={index} style={{ display: 'flex', alignItems: 'center', padding: '8px 0', borderBottom: '1px solid #ddd' }}>
                                                                <div>{item.description}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {invoice.line_items && invoice.line_items.length > 0 ? (
                                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                        {invoice.line_items.map((item, index) => (
                                                            <li key={index}>{item?.product_name}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {invoice.line_items && invoice.line_items.length > 0 ? (
                                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                                        {invoice.line_items.map((item, index) => (
                                                            <li key={index}>{item?.quantity}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice.amount_due)}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.paid}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice.amount_paid)}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.payment?.payment_status || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.payment?.payment_date || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.payment?.seller_message || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice?.payment?.payment_fee) || '0'}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice?.payment?.payment_amount) || '0'}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>${convertStripeAmount(invoice?.payment?.amount_refunded) || '0'}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.payment?.payout_date || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {
                                                    invoice?.payment?.payment_metadata?.otherInfo || ""
                                                }
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {
                                                    invoice?.payment?.payment_metadata?.productInfo || ""
                                                }
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                {invoice ? renderCreditNote(invoice) : ''}
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {
                            isLoading || filterInvoiceHistory.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please select an invoice status and date range, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>

                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>
                {popupVisible && (
                    <div className="popupInvoiceStatus" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x - 20 }}>
                        <div className="popupInvoiceStatusList">
                            {filterOptions
                                .map((option) => (
                                    <div key={option.id}>
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <input
                                                type="checkbox"
                                                checked={option.selected}
                                                onChange={() => toggleSelect(option.id)}
                                            />
                                            {option.name}
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="filterseperater"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelFilter}>Cancel</button>
                            <button className="fillButton" onClick={() => { handleApplyFilter() }}>APPLY</button>
                        </div>
                    </div>
                )}

                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}
                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '48%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedStartDate}
                                    selected={selectedStartDate ? selectedStartDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div className="dashLine"></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '48%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedEndDate}
                                    selected={selectedEndDate ? selectedEndDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelDateFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default ReportHistory;
function convertStripeAmount(paymentAmount) {
    // Check if paymentAmount is null, undefined, or an empty string
    if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
        return 0; // You can choose to return null or throw an error instead
    }
    // Ensure the paymentAmount is a number
    const amount = Number(paymentAmount);
    if (isNaN(amount)) {
        return 0; // Return 0 or handle as needed
    }
    // Divide the payment amount by 100 to convert cents to dollars
    return amount / 100;
}
function formatDate(unixTimestamp) {
    // Check if the input is null, undefined, or not a valid number
    if (!unixTimestamp || isNaN(unixTimestamp)) {
        return "Invalid Date";
    }

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Check if the created date is invalid (e.g., input was not a number)
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}